import {FetchManager} from 'js-pimcore-formbuilder';
import {ConditionalLogic} from './Form/conditionalLogic';
import {Repeater} from 'js-pimcore-formbuilder';
import {DropzoneHandler} from 'js-pimcore-formbuilder';

import CookieConsent from '@components/CookieConsent/CookieConsent'

window.dataLayer = window.dataLayer || [];
export default class Form {
  constructor(rootNode) {
    Form.root = rootNode
    Form.tabs = rootNode.querySelector('.fb-tabs')
    Form.messages = rootNode.querySelector('.fb-messages')
    Form.navigation = rootNode.querySelector('.fb-navigation')
    Form.tabsAnchor = rootNode.querySelector('.fb-tabs-anchor')
    Form.instance = rootNode.querySelector('.formbuilder:not(.set_fb)');
    Form.rows = Form.instance.querySelectorAll('.fb-row');
    Form.chapters = Form.instance.querySelectorAll('.fb-tab-chapter')
    Form.summary = Form.instance.querySelector('.fb-summary')
    Form.chaptersFieldsObj = []
    Form.chaptersObj = []
    Form.fieldsObj = []
    Form.returnChapter = null

    if (Form.prepare()) {
      Form.setup()
      Form.events()
    }
  }

  /**
   * Prepare custom logic
   */
  static prepare() {
    let current = Form.instance.querySelectorAll('.fb-tab-chapter').length > 0 ? null : 'simple'
    let stepI = 1

    if (current === 'simple') {
      Form.chaptersObj.push({
        id: 'simple',
        label: 'Simple',
        active: false,
      })
    }

    Form.rows.forEach((element) => {
      // Chapters
      if (element.classList.contains('fb-tab-chapter')) {
        const id = element.getAttribute('data-element-id')
        const label = element.getAttribute('data-element-label')

        if (id && label) {
          current = id

          Form.chaptersObj.push({
            id: id,
            label: label,
            active: false,
          })

          stepI++
        }
      } else {
        const id = element.getAttribute('data-element-id')

        if (id) {
          if (current) {
            if (Form.chaptersFieldsObj[current] === undefined) {
              Form.chaptersFieldsObj[current] = []
            }

            Form.chaptersFieldsObj[current].push(id)
            element.setAttribute('data-chapter-id', current)
          }

          Form.fieldsObj.push({id: id})
        }
      }
    })

    if (Form.rows.length > 0) {
      return true
    }

    return false
  }

  /**
   * Setup
   */
  static setup() {
    // Init fb scripts
    Form.init()

    // Set ready state
    Form.instance.classList.add('ready')

    // Start with first chapter
    Form.startChapter(Form.chaptersObj[0])
  }

  /**
   * Initialize formbuilder scripts
   */
  static init() {
    if (Form.instance.classList.contains('ajax-form')) {
      new FetchManager(Form.instance, {
        resetFormMethod: function() {},
        elementTransformer: {
          addValidationMessage: function(form, field, messages) {
            Form.showError(field, messages)
          },
          removeFormValidations: function(form) {
            const errorElements = form.querySelectorAll('.has-error');
            if(errorElements.length > 0){
              errorElements.forEach(el => {
                Form.showError(el, false)
              })
            }
          }
        }
      })
    }

    new Repeater(Form.instance, {
      addClass: 'tw-mt-5 IconLink has-plus-right',
      removeClass: 'tw-text-red-500 tw-text-sm',
    })

    new ConditionalLogic(Form.instance, {
      elementTransformer: {
        show: (el) => {
          el.closest('.fb-row').classList.remove('fb-hide')
        },
        hide: (el) => {
          el.closest('.fb-row').classList.add('fb-hide')
        }
      },
    })

    Form.instance.classList.add('set_fb')

    new DropzoneHandler(Form.instance);
    Form.instance.addEventListener('formbuilder.dynamic_multi_file.init',
      function(ev) {
        ev.detail[2].addRemoveLinks = false
      }
    )

    Form.instance.addEventListener('formbuilder.success',
      function (e) {
        const messages = e.detail.messages;
        const redirect = e.detail.redirect;

        CookieConsent.trackEvent('form-sent', Form.getFormName())

        if (redirect) {
          document.location.href = redirect;
        } else {
          Form.showMessage((messages && messages[0] && messages[0]['message'] ? messages[0]['message'] : null), 'success')
        }
      }
    )

    Form.instance.addEventListener('formbuilder.error',
      function (e) {
        const response = e.detail.response;
        Form.showMessage(response, 'error')
      }
    )

    Form.instance.addEventListener('formbuilder.fatal',
      function (e) {
        const response = e.detail.response;
        Form.showMessage(response.error, 'error')
      }
    )

    // Multiple inputs solution
    Form.instance.querySelectorAll('.js-add-multi-input').forEach(input => input.addEventListener('click', () => {
      const list = Form.instance.querySelector(input.getAttribute('data-list-selector'));
      let counter = list.getAttribute('data-widget-counter') || list.children.length
      let newWidget = list.getAttribute('data-prototype')
      let removeTemplate = Form.instance.querySelector('.fb-tmpl-remove-multi-input').innerHTML;

      newWidget = newWidget.replace(/__name__/g, counter)

      counter++

      list.setAttribute('data-widget-counter', counter)

      const newElem = Form.createElementFromHTML(list.getAttribute('data-widget-tags'));

      list.append(newElem)

      newElem.innerHTML = newWidget + removeTemplate;

      Form.bindMultiInputEvents(newElem.querySelector('.js-remove-multi-input'))

      // Click accordion button 2 times to recalculate height
      const collapse = input.closest('.fb-collapse')
      if (collapse) {
        const button = collapse.querySelector('.item-button');
        button.click();
        button.click();
      }
    }))

    Form.loadValues()
  }

  /**
   *
   * @param el
   */
  static bindMultiInputEvents(el) {
    if(!el) {
      return
    }

    el.addEventListener('click', () => {
      const list = el.closest('[data-widget-counter]');
      let counter = list.getAttribute('data-widget-counter');

      counter--;

      list.setAttribute('data-widget-counter', counter);

      el.closest('.multi-input-wrap').remove();
    });
  }

  /**
   * Events
   */
  static events() {
    Form.root.querySelectorAll('.fb-tab').forEach(tab => {
      tab.addEventListener('click', () => {
        const chapter = Form.getChapter(tab.getAttribute('data-chapter-id'))

        if (chapter) {
          Form.changeChapter(chapter)
        }
      })
    })

    Form.instance.querySelectorAll('.fb-button-prev').forEach(button => button.addEventListener('click', () => {
      Form.goToChapter('prev')
    }))

    Form.instance.querySelectorAll('.fb-button-next').forEach(button => button.addEventListener('click', () => {
      Form.goToChapter('next')
    }))

    Form.instance.querySelectorAll('.fb-button-submit').forEach(button => button.addEventListener('click', () => {
      Form.instance.classList.add('submitting')
    }))

    Form.instance.addEventListener('change', () => {
      if (!Form.instance.getAttribute('data-started')) {
        CookieConsent.trackEvent('form-start', Form.getFormName())

        Form.instance.setAttribute('data-started', true)
      }

      Form.onChange()
    })

    Form.instance.addEventListener('submit', () => {
      Form.storeValues()
    })

    if (Form.tabs && Form.tabsAnchor) {
      document.addEventListener(
        'scroll',
        (event) => {
          if (Form.root.offsetTop
            && Form.tabsAnchor.offsetTop
            && Form.tabsAnchor.offsetTop !== Form.tabs.offsetTop
          ) {
            Form.tabs.classList.add('is-sticky')
          } else {
            Form.tabs.classList.remove('is-sticky')
          }
        },
        {passive: true},
      )
    }
  }

  /**
   * Start chapter and show questions
   * @param chapter
   */
  static startChapter(chapter) {
    if (Form.chaptersFieldsObj[chapter.id]) {
      const state = {'chapterId': chapter.id}
      const title = chapter.label
      const url = '#' + chapter.id
      const currentChapter = document.querySelector('#fb-tab-chapter-' + chapter.id)

      history.pushState(state, title, url)

      document.querySelectorAll('.fb-tab-chapter').forEach(tab => tab.classList.remove('active'));

      Form.rows.forEach(row => row.classList.remove('active'));

      if(currentChapter){
        currentChapter.classList.add('active')
        if(currentChapter.classList.contains('fb-tab-chapter-summary')) {
          currentChapter.classList.add('done')
        }
      }

      for (const key in Form.chaptersFieldsObj[chapter.id]){
        const value = Form.chaptersFieldsObj[chapter.id][key];
        Form.instance.querySelectorAll('.fb-row[data-element-id="' + value + '"]').forEach(el => el.classList.add('active'));
      }

      for (const key in Form.chaptersObj){
        const value = Form.chaptersObj[key];

        Form.chaptersObj[key]['active'] = false

        if (value.id === chapter.id) {
          Form.chaptersObj[key]['active'] = true
        }
      }
    }

    if (Form.getNextChapter()) {
      Form.instance.classList.add('show-next')
    } else {
      Form.instance.classList.remove('show-next')
    }

    if (Form.getPreviousChapter()) {
      Form.instance.classList.add('show-prev')
    } else {
      Form.instance.classList.remove('show-prev')
    }

    if (Form.getCurrentChapter().id === Form.getLastChapter().id) {
      Form.instance.classList.add('show-final')
    } else {
      Form.instance.classList.remove('show-final')
    }
  }

  /**
   * Generate summary
   */
  static summarize() {
    if(!Form.summary){
      return;
    }

    Form.instance.querySelectorAll('.fb-summary-items').forEach(item => item.innerHTML = '');

    Form.instance.querySelectorAll('.fb-row[data-element-label]').forEach((row) => {
      const label = row.getAttribute('data-element-label')
      const parent = row.closest('.fb-row[data-chapter-id]')
      let chapterId = row.getAttribute('data-chapter-id')

      if(!chapterId && parent){
        chapterId = parent.getAttribute('data-chapter-id');
      }

      if(!chapterId){
        return;
      }

      const chapter = Form.summary.querySelector('.fb-summary-chapter[data-chapter-id="' + chapterId + '"]')
      const value = Form.getValues(row, true, false)

      if (chapter) {
        chapter.classList.remove('tw-hidden')
        const items = document.createElement('div');
        chapter.querySelector('.fb-summary-items').append(items);
        items.outerHTML = Form.generateItem(row, label, value);
      }
    })

    Form.summary.querySelectorAll('.fb-summary-items').forEach((item) => {
      const chapterSummary = item.closest('.fb-summary-chapter')
      chapterSummary.classList.toggle('tw-hidden', item.querySelectorAll('.fb-summary-item').length === 0);
    })
  }

  /**
   * Get values
   *
   * @param row
   * @param direct
   * @param required
   * @returns {[]}
   */
  static getValues(row, direct = false, required = false) {
    const inputs = Form.getInputs(row, direct, required)

    return Form.getInputValues(inputs)
  }

  /**
   *
   * @returns {[]}
   * @param inputs
   */
  static getInputValues(inputs) {
    let value = []

    inputs.forEach((input) => {
      if (input.getAttribute('type') === 'radio'
        || input.getAttribute('type') === 'checkbox') {
        let tmp = input.parentElement.querySelector(':checked')?.parentElement.innerText;

        if(tmp && input.value === '1') {
          tmp = 'Ja'
        }

        if (tmp) {
          value.push(tmp);
        }
      } else if (input.value && input.value !== '') {
        value.push(input.value)
      }
    })

    return value
  }

  /**
   *
   * @param row
   * @param direct
   * @param required
   * @returns {jQuery}
   */
  static getInputs(row, direct = false, required = false) {
    let requiredAttr = ''
    let inputs

    if (required) {
      requiredAttr = ':required'
    }

    if (direct) {
      inputs = row.querySelectorAll(':scope > input' + requiredAttr + ', :scope > textarea' + requiredAttr + ', :scope > select' + requiredAttr + ', :scope > .tw-flex-row>.radio input' + requiredAttr + ', :scope > .checkbox input' + requiredAttr)
    } else {
      inputs = row.querySelectorAll('input' + requiredAttr + ', textarea' + requiredAttr + ', select' + requiredAttr)
    }

    return inputs
  }

  /**
   *
   * @param row
   * @param label
   * @param value
   * @returns {*}
   */
  static generateItem(row, label, value) {
    let template = null

    if(value.length > 0) {
      template = Form.instance.querySelector('.fb-tmpl-item').innerHTML;
    } else if(row.classList.contains('fb-collapse') && Form.getValues(row, false, false).length > 0) {
      template = Form.instance.querySelector('.fb-tmpl-headline').innerHTML;
    }

    if(template && label) {
      const tmp = document.createElement('div');
      tmp.innerHTML = template;

      tmp.querySelectorAll('.label').forEach(el => el.innerHTML = label);

      if(value) {
        tmp.querySelectorAll('.value').forEach(el => el.innerHTML = value);
      }

      template = tmp.innerHTML;
      tmp.remove();
    }

    return template
  }

  /**
   * Turn to prev chapter
   * @returns {boolean}
   * @param dir
   */
  static goToChapter(dir) {
    let chapter = null

    switch (dir) {
      case 'prev':
        chapter = Form.getPreviousChapter()
        break;

      case 'next':
        if (!Form.validateChapter('nextChapter')) {
          return false
        }

        if (Form.returnChapter) {
          Form.changeChapter(Form.returnChapter)
          Form.returnChapter = null
          return true
        }

        chapter = Form.getNextChapter()
        break
    }

    if (chapter) {
      Form.changeChapter(chapter)
    }

    return true
  }

  /**
   * Change chapter
   * @param newChapter
   */
  static changeChapter(newChapter) {
    const currentChapter = Form.getCurrentChapter()
    const questions = Form.chaptersFieldsObj[currentChapter.id]

    if (questions) {
      Form.root.scrollIntoView()
      Form.instance.querySelectorAll('.fb-row.active').forEach(el => el.classList.remove('active'));
      Form.startChapter(newChapter)
    }
  }

  /**
   * Return current active chapter
   * @returns {null}
   */
  static getCurrentChapter() {
    let chapter = null

    for (const key in Form.chaptersObj){
      const value = Form.chaptersObj[key];
      if (value.active === true) {
        chapter = value
      }
    }

    return chapter
  }

  /**
   *
   * @param id
   * @returns {null}
   */
  static getChapter(id) {
    let chapter = null

    for (const key in Form.chaptersObj){
      const value = Form.chaptersObj[key];
      if (value.id === id) {
        chapter = value
      }
    }

    return chapter
  }

  /**
   * Get last chapter
   */
  static getLastChapter() {
    let chapter = null

    for (const key in Form.chaptersObj){
      const value = Form.chaptersObj[key];
      chapter = value;
    }

    return chapter
  }

  /**
   * Turn to next chapter
   * @returns {boolean}
   */
  static getNextChapter() {
    const currentChapter = Form.getCurrentChapter()
    let chapter = null
    let selectNext = false

    for (const key in Form.chaptersObj){
      const value = Form.chaptersObj[key];

      if (selectNext) {
        chapter = value
        break;
      }

      if (value.id === currentChapter.id) {
        selectNext = true
      }
    }

    return chapter
  }

  /**
   * Get previous chapter
   * @returns {null}
   */
  static getPreviousChapter() {
    const currentChapter = Form.getCurrentChapter()
    let chapter = null
    let selectNext = false


    for (const key in Form.chaptersObj.slice().reverse()){
      const value = Form.chaptersObj[key];

      if (selectNext) {
        chapter = value
        break;
      }

      if (value.id === currentChapter.id) {
        selectNext = true
      }
    }

    return chapter
  }

  /**
   * Validate inputs
   * @param trigger
   * @returns {boolean}
   */
  static validateChapter(trigger) {
    const elements = Form.instance.querySelectorAll('.fb-row.active:not(.fb-hide)');
    const count = elements.length
    const chapter = Form.getCurrentChapter()

    let returnValue
    let completed = 0
    let scrollTo = null

    elements.forEach((row) => {

      if (Form.checkCondition(row)) {
        Form.showErrorRow(row, false)
        row.classList.add('has-value')

        completed++
      } else {
        if (trigger === 'nextChapter') {
          Form.showErrorRow(row, true)

          if (!scrollTo) {
            scrollTo = row
          }
        }

        row.classList.remove('has-value')
      }

      if(Form.instance.querySelectorAll('.has-error').length > 0) {
        Form.toggleSubInputErrors(row)
      }
    })

    if (completed >= count) {
      Form.setChapterStatus(chapter, 'done', true)

      returnValue = true
    } else {
      Form.setChapterStatus(chapter, 'done', false)

      if (trigger === 'nextChapter') {
        if (scrollTo[0]) {
          scrollTo[0].scrollIntoView()
        }
      }

      returnValue = false
    }

    return returnValue
  }

  /**
   *
   * @param row
   */
  static toggleSubInputErrors(row) {
    const requiredSubInputs = Form.getInputs(row, false, true)

    if(requiredSubInputs.length > 0) {
      requiredSubInputs.forEach((input) => {
        const inputsRow = input.closest('.fb-row')

        if (!Form.checkCondition(inputsRow)) {
          inputsRow.classList.add('has-error')
        } else {
          inputsRow.classList.remove('has-error')
        }
      })
    }
  }

  /**
   * Show error
   * @param input
   */
  static showError(input, messages) {
    const row = input.closest('.fb-row[data-chapter-id]')

    if(row.length > 0) {
      const chapter = row.getAttribute('data-chapter-id')

      Form.showErrorRow(row, true, messages);
      Form.changeChapter(Form.getChapter(chapter));
    }
  }

  /**
   *
   * @param row
   * @param bool
   */
  static showErrorRow(row, bool, messages = null) {
    const chapterRow = row.getAttribute('data-chapter-id')

    if (bool) {
      row.classList.add('has-error')

      if (row.querySelectorAll('.error-message').length === 0) {
        Form.showErrorRowMessage(row, bool, messages)
      }
    } else {
      row.classList.remove('has-error')

      Form.showErrorRowMessage(row, bool, messages)
    }

    if(chapterRow) {
      Form.showErrorChapter(chapterRow)
    }
  }

  /**
   *
   * @param chapterId
   */
  static showErrorChapter(chapterId) {
    if(Form.tabs) {
      const chapterTab = Form.tabs.querySelector(
        '[data-chapter-id="' + chapterId + '"]').closest('li');
      const errors = Form.root.querySelectorAll(
        '.fb-row.has-error[data-chapter-id="' + chapterId + '"]')

      if (errors.length > 0) {
        chapterTab.classList.add('has-error')
      } else {
        chapterTab.classList.remove('has-error')
      }
    }
  }

  /**
   *
   * @param row
   * @param bool
   */
  static showErrorRowMessage(row, bool, messages = null) {
    const template = Form.instance.querySelector('.fb-tmpl-error').innerHTML;

    if (bool) {
      const error = document.createElement('div');
      row.append(error)
      error.outerHTML = template;

      if(messages) {
        const elements = row.querySelectorAll('.content');

        elements.forEach((el, index) => {
          if(messages[index]){
            el.innerHTML = messages[index]
          }else{
            console.log('Unable to get error message');
          }
        })
      }
    } else {
      row.querySelectorAll('.error-icon, .error-message').forEach(el => el.remove());
    }
  }

  /**
   *
   * @param row
   * @returns {boolean}
   */
  static checkCondition(row) {
    const requiredInputs = Form.getInputs(row, false, true)

    if (requiredInputs.length === 0) {
      return true
    }

    let inputArr = []
    let valueCount = 0

    requiredInputs.forEach((input) => {
      inputArr.push(input.getAttribute('name'))

      if(Form.getInputValues([input]).length > 0) {
        valueCount++
      }
    })

    inputArr = inputArr.filter((v, i, a) => a.indexOf(v) === i)

    return inputArr.length === valueCount
  }

  /**
   * Check changes directly
   */
  static onChange() {
    Form.validateChapter(null)

    // Summary
    Form.summarize()
  }

  /**
   * Set status class
   * @param chapter
   * @param status
   * @param value
   */
  static setChapterStatus(chapter, status, value) {
    const elements = Form.root.querySelectorAll('#fb-tab-chapter-' + chapter.id + ', #fb-summary-' + chapter.id)

    if (status && value) {
      elements.forEach(el => el.classList.add(status))
    } else if (status && !value) {
      elements.forEach(el => el.classList.remove(status))
    }
  }

  /**
   *
   * @param messages
   * @param type
   */
  static showMessage(messages, type) {
    let template
    let content = []

    Form.messages.innerHTML = ''

    if(typeof messages === 'string') {
      content.push(messages)
    } else if(messages.validation_errors) {
      Object.entries(messages.validation_errors).forEach(([key, message]) => {
        content.push(message)
      })
    }

    if(type === 'success') {
      template = Form.root.querySelector('.fb-tmpl-message-success').innerHTML

      Form.navigation.remove()
    } else {
      template = Form.root.querySelector('.fb-tmpl-message-error').innerHTML
    }

    if(template) {
      Form.messages.innerHTML = template
      Form.messages.querySelector('.content').innerHTML = content.join('<br/>')
    }
  }

  static storeValues() {
    if(!localStorage) {
      return false
    }

    const inputs = Form.root.querySelectorAll('[name]')
    const ignoredFields = [
      'formId',
      'formCl',
      'inputUserName',
      'formRuntimeData',
      'formRuntimeDataToken',
      '_token',
    ]
    const values = {}

    Object.entries(inputs).forEach(([key, input]) => {
      if (input.getAttribute('id')) {
        const fieldName = input.getAttribute('id').replace(Form.getFormName() + '_', '')

        if (
          fieldName.indexOf('field_') < 0
          && !ignoredFields.includes(fieldName)
          && input.value
        ) {
          let add = false

          if(['radio', 'checkbox'].includes(input.getAttribute('type'))) {
            if(input.checked) {
              add = true
            }
          } else {
            add = true
          }

          if(add) {
            values[fieldName] = input.value
          }
        }
      }
    })

    localStorage.setItem('swz_form_data', JSON.stringify(values));
  }

  static loadValues() {
    if(!localStorage) {
      return false
    }

    if(!localStorage.getItem('swz_form_data')) {
      return false
    }

    try {
      const values = JSON.parse(localStorage.getItem('swz_form_data'));

      Object.entries(values).forEach(([key, value]) => {
        const input = Form.root.querySelector('[id^="' + Form.getFormName() + '_' + key + '"]')

        if(input) {
          if(['radio', 'checkbox'].includes(input.getAttribute('type'))) {
            input.checked = true
          } else {
            input.value = value
          }
        }
      })
    } catch (e) {
      console.info(e)
    }
  }

  /**
   *
   * @returns {string}
   */
  static getFormName() {
    return Form.instance.getAttribute('name')
  }

  static createElementFromHTML(htmlString) {
    const div = document.createElement('div');
    div.innerHTML = htmlString.trim();

    // Change this to div.childNodes to support multiple top-level nodes.
    return div.firstChild;
  }
}